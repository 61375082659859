<template>
  <div>
    <b-card>
      <validation-observer
        ref='form'
        v-slot='{invalid}'
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-success my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>

        <b-form
          v-else
          novalidate
          @submit.prevent='onSubmit'
        >
          <b-row>
            <!-- name -->
            <b-col cols='6'>
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.name')"
                vid='name'
                rules='required'
              >
                <b-form-group
                  :label="$t('labels.name')"
                >
                  <b-form-input
                    v-model='form.name'
                    type='text'
                    :placeholder="$t('labels.name')"
                    autocomplete='off'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols='6'>
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.username')"
                vid='username'
                rules='required'
              >
                <b-form-group
                  :label="$t('labels.username')"
                >
                  <b-form-input
                    v-model='form.username'
                    type='text'
                    :placeholder="$t('labels.username')"
                    autocomplete='off'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- email -->
            <b-col cols='6'>
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.email')"
                vid='email'
              >
                <b-form-group
                  :label="$t('labels.email')"
                >
                  <b-form-input
                    v-model='form.email'
                    type='email'
                    :placeholder="$t('labels.email')"
                    autocomplete='off'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
<!--            <b-col cols='6'>-->
<!--              <validation-provider-->
<!--                v-slot='{ errors }'-->
<!--                :name="$t('labels.phone')"-->
<!--                vid='phone'-->
<!--                rules='min:10|max:14'-->
<!--              >-->
<!--                <b-form-group-->
<!--                  :label="$t('labels.phone')"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                    style='direction: ltr !important;text-align: right !important;'-->
<!--                    class='phone-number'-->
<!--                    v-model='form.phone'-->
<!--                    type='text'-->
<!--                    :placeholder="$t('labels.phone')"-->
<!--                  />-->
<!--                  <small class='text-danger'>{{ errors[0] }}</small>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->

            <!-- password -->
            <b-col cols='6'>
              <validation-provider
                v-slot='{ errors }'
                :name="$t('labels.password')"
                vid='password'
                :rules="isEdit?'':'required'"
              >
                <b-form-group
                  :label="$t('labels.password')"
                >
                  <b-form-input
                    v-model='form.password'
                    type='password'
                    :placeholder="$t('labels.password')"
                    autocomplete='new-password'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              md='6'
              cols='12'
            >
              <validation-provider
                v-slot='{ errors }'
                vid='roles'
                :name="$t('models.roles')"
                rules='required'
              >
                <b-form-group
                  :label="$t('models.roles')"
                >
                  <v-select
                    v-model='form.roles'
                    :dir='$store.state.appConfig.layout.direction'
                    multiple
                    label='name'
                    :options='roles'
                    :reduce='item => item.id'
                  />
                  <small class='text-danger'>{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols='12'>
              <ValidationProvider
                v-slot='{ errors }'
                vid='image'
                :name="$t('labels.image')"
              >
                <ImagePreview
                  v-model='image'
                  :label="$t('labels.image')"
                />
                <small class='text-danger'>{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <!-- checkbox -->
            <b-col cols='12'>
              <b-card-text class='mb-0'>
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model='form.status'
                checked='1'
                value='1'
                unchecked-value='0'
                name='check-button'
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols='12 text-right'>
              <LoadingButton />
              <b-button
                type='reset'
                variant='outline-secondary'
                @click='reset()'
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      roles: [],
      image: null,
      form: {
        name: null,
        email: null,
        password: null,
        username: null,
        phone: null,
        status: 1,
        roles: [],
        image: null,
      },
      isDataLoaded: false,

    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    this.getRoles()
    if (this.isEdit) {
      this.loadData()
    } else {
      this.isDataLoaded = true
    }
  },
  methods: {
    reset() {
      this.image = null
    },
    getRoles() {
      this.axios.get('/lists/role')
        .then(res => {
          this.roles = res.data.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            status: data.status,
            username: data.username,
            roles: data.roles.map(item => item.id),
          }
          this.image = data.image
          this.isDataLoaded = true
        })
    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.image instanceof File) && $form.image != 'delete') {
        delete $form.image
      }
      return $form
    },
  },

}
</script>
<style lang='scss' scoped>
html[dir="rtl"] {
  .phone-number {
    direction: ltr;
    text-align: right;
  }
}
</style>
